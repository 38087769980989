import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <p><strong>Stuart Pettigrew Design acknowledges the Wurundjeri People of the Kulin Nation, who are the Traditional Owners of this land.</strong></p>
        <p>Stuart Pettigrew © 5/1 Grattan Street Prahran VIC 3181</p>
      </footer>
    )
  }
}

export default Footer
