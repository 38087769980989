import React from 'react'

import CloseIcon from '../assets/images/close.svg'

const EmailPopup = () => {

  const [visible, setVisible] = React.useState(true)
  const [email, setEmail] = React.useState('')
  const [complete, setComplete] = React.useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    // TODO: send email to Hubspot and trigger email

    fetch(`https://hooks.zapier.com/hooks/catch/2181815/bqmzifq/?email=${email}`)
      .then(() => {
        setEmail('')
        setComplete(true)
      })
      .catch(e => console.log(e))

  }

  const onChange = (event) => {
    setEmail(event.target.value)
  }

  const renderMessage = () => (
    <>
      <h6>Your free copy of</h6>
      <h5>A Guide to Pricing your Brand</h5>
      <h6>Is on it's way!</h6>
    </>
  )

  const renderForm = () => (
    <form onSubmit={onSubmit}>
      <h6>Download a free copy of</h6>
      <h5>A Guide to Pricing your Brand</h5>
      <div className='email-popup__field'>
        <input
          type='email' name='email' placeholder='Email'
          onChange={onChange} value={email} required
        />
        <button type='submit'>Get my copy</button>
      </div>
    </form>
  )

  React.useEffect(() => {

  }, [])

  return (
    <div className={`email-popup ${visible ? 'email-popup--visible' : ''}`}>
      <button type='button' onClick={() => setVisible(false)}>
        <img src={CloseIcon} alt={'Close Popup'} />
      </button>

      {complete ? renderMessage() : renderForm()}
    </div>
  )
}

export default EmailPopup