import { Link } from 'gatsby'
import React, { Component } from 'react'

import Logo from '../assets/images/logo.svg'
import LeftArrow from '../assets/images/close.svg'

class Header extends Component {

  state = {
    offCanvas: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
    document.body.classList.toggle('menu-active')
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      //onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        { React.cloneElement(this.props.children, { offCanvas: this.state.offCanvas }) }
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' {...props}><img className='header__logo' src={Logo} alt='Stuart Pettigrew' /></Link>
          </div>
        </header>
        <div className='header__spacer' />
        <div onClick={this._toggleOffCanvas} className={ offCanvas ? 'menu active' : 'menu' }>
          <span>Menu</span>
          <div className='menu__content'>
            <img className='menu__arrow' src={LeftArrow} alt='Stuart Pettigrew' />
            <ul>
              <li><Link to="/work" {...props}>Work</Link></li>
              <li><Link to="/annual-reports" {...props} className='sub'>Annual Reports</Link></li>
              <li><Link to="/branding-logo-design" {...props} className='sub'>Brand Identity</Link></li>
              <li><Link to="/campaigns" {...props} className='sub'>Campaigns</Link></li>
              <li><Link to="/digital" {...props} className='sub'>Digital</Link></li>
              <li><Link to="/" state={{ noAni: true }}>Services</Link></li>
              <li><Link to="/people" {...props}>About</Link></li>
              <li><Link to="/contact" {...props}>Contact</Link></li>
              <li><Link to="/archive" {...props}>Archive</Link></li>
              <li className="menu__social-item menu__social-item--first"><a href="https://www.instagram.com/stuartpettigrewdesign/" target="_blank" rel="noreferrer">Instagram</a></li>
              <li className="menu__social-item"><a href="https://www.linkedin.com/company/stuart-pettigrew-design/" target="_blank" rel="noreferrer">LinkedIn</a></li>
              <li className="menu__social-item"><a href="mailto:studio@spdesign.com.au" target="_blank" rel="noreferrer">Email</a></li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

Header.defaultProps = {
  location: {},
  data: {},
}

export default Header
